table {
    border-collapse: collapse;
    width: 75%;
    text-align: center;    
}
@media screen and (max-width: 1000px){
    table {
        width: 100%;
    }
}
td, th {
border: 1px solid #dddddd;
text-align: left;
padding: 8px;
}

tr:nth-child(even) {
background-color: #dddddd;
}